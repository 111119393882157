import React from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BlogPost from "../components/blogPost";

const uri = "/about/";

const PrivacyPolicyPage = ({ location }) => {
  const searchQuery = location.state?.searchQuery;

  return (
    <Layout pageInfo={{ pageName: "private-policy", searchQuery: searchQuery }}>
      <Seo title="Privacy Policy" keywords={[`privacy`, `policy`]} description="Privacy Policy" url={uri} />
      <Container fluid>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <BlogPost title="Privacy Policy" adCount={0}>
          <Container fluid>
            <Row className="d-flex flex-wrap">
              <p>
                ILoveItRecipes.com, takes your privacy seriously. Please read the following to
                learn more about our privacy policy.{" "}
              </p>

              <h2>Who we are</h2>
              <p>Our website address is: https://www.ILoveItRecipes.com.</p>

              <h2>What This Privacy Policy Covers</h2>

              <p>
                This policy covers how ILoveItRecipes.com treats personal information that ILoveItRecipes.com collects and receives,
                including information related to your past use of ILoveItRecipes.com products and services. Personal
                information is information about you that is personally identifiable like your name, address, email
                address, or phone number, and that is not otherwise publicly available.{" "}
              </p>
              <p>
                This policy does not apply to the practices of companies that ILoveItRecipes.com does not own or control, or
                to people that ILoveItRecipes.com does not employ or manage.{" "}
              </p>

              <h2>What personal data we collect and why we collect it</h2>

              <h4>Comments</h4>
              <p>
                When visitors leave comments on the site we collect the data shown in the comments form, and also the
                visitor's IP address and browser user agent string to help spam detection.
              </p>
              <p>
                An anonymized string created from your email address (also called a hash) may be provided to the
                Gravatar service to see if you are using it. The Gravatar service privacy policy is available here:
                https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the
                public in the context of your comment.
              </p>

              <h4>Media</h4>
              <p>
                If you upload images to the website, you should avoid uploading images with embedded location data (EXIF
                GPS) included. Visitors to the website can download and extract any location data from images on the
                website.
              </p>

              <h4>Cookies and Local Storage</h4>
              <p>
                When you log in, we save a small amount of data in your browser's local storage, which is similar to a
                cookie. This information saves your login information and your screen display choices, so you stay
                logged in upon a browser refresh or restart. If you log out, the local storage information will be
                cleared.
              </p>

              <h4>Embedded content from other websites</h4>
              <p>
                Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded
                content from other websites behaves in the exact same way as if the visitor has visited the other
                website.
              </p>
              <p>
                These websites may collect data about you, use cookies, embed additional third-party tracking, and
                monitor your interaction with that embedded content, including tracking your interaction with the
                embedded content if you have an account and are logged in to that website.
              </p>

              <h4>Analytics</h4>
              <p>ILoveItRecipes.com uses Google Analytics to track web site usage.</p>

              <h4>
                <strong>Information Collection and Use</strong>
              </h4>
              <p>
                ILoveItRecipes.com collects personal information when you register with ILoveItRecipes.com, when you use
                ILoveItRecipes.com products or services, when you visit ILoveItRecipes.com pages and when you enter
                promotions or sweepstakes.{" "}
              </p>
              <p>
                When you register we may ask for information such as your email address and ZIP code. When you register
                with ILoveItRecipes.com and sign in to our services, you are not anonymous to us. ILoveItRecipes.com
                automatically receives and records information on our server logs from your browser, including your IP
                address, ILoveItRecipes.com cookie information, and the page you request.{" "}
              </p>
              <p>
                ILoveItRecipes.com uses information for the following general purposes: to customize the advertising and
                content you see, fulfill your requests for products and services, improve our services, contact you,
                conduct research, and provide anonymous reporting for internal and external clients.{" "}
              </p>

              <h2>Who we share your data with</h2>
              <p>
                ILoveItRecipes.com does not rent, sell, or share personal information about you with other people or
                nonaffiliated companies except to provide products or services you've requested, when we have your
                permission, or under the following circumstances: We provide the information to trusted partners who
                work on behalf of or with ILoveItRecipes.com under confidentiality agreements. These companies may use
                your personal information to help ILoveItRecipes.com communicate with you about offers from
                ILoveItRecipes.com and our marketing partners. However, these companies do not have any independent
                right to share this information.{" "}
              </p>
              <p>
                ILoveItRecipes.com displays targeted advertisements based on personal information. Advertisers
                (including ad serving companies) may assume that people who interact with, view, or click targeted ads
                meet the targeting criteria-for example, women ages 18-24 from a particular geographic area.
                ILoveItRecipes.com does not provide any personal information to the advertiser when you interact with or
                view a targeted ad. However, by interacting with or viewing an ad you are consenting to the possibility
                that the advertiser will make the assumption that you meet the targeting criteria used to display the
                ad.{" "}
              </p>

              <h2>How long we retain your data</h2>
              <p>
                If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can
                recognize and approve any follow-up comments automatically instead of holding them in a moderation
                queue.
              </p>
              <p>
                For users that register on our website (if any), we also store the personal information they provide in
                their user profile. All users can see, edit, or delete their personal information at any time (except
                they cannot change their username). Website administrators can also see and edit that information.
              </p>

              <h2>What rights you have over your data</h2>
              <p>
                If you have an account on this site, or have left comments, you can request to receive an exported file
                of the personal data we hold about you, including any data you have provided to us. You can also request
                that we erase any personal data we hold about you. This does not include any data we are obliged to keep
                for administrative, legal, or security purposes.
              </p>

              <h2>Where we send your data</h2>
              <p>Visitor comments may be checked through an automated spam detection service.</p>

              <h2>Changes to this Privacy Policy</h2>
              <p>
              ILoveItRecipes.com may update this policy. We will notify you about significant changes in the way we treat
                personal information by sending a notice to the primary email address specified in your
                ILoveItRecipes.com account or by placing a prominent notice on our site.
              </p>

              <h2>Contact Us</h2>
              <p>
                Contact us at info@iloveitrecipes.com
              </p>
            </Row>
          </Container>
        </BlogPost>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicyPage;
